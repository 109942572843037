import "../../styles/index.css";
import logo from "../../assets/logo_cropped.png";
import { RiFacebookLine, RiInstagramLine, RiPhoneLine, RiMailLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { Typography } from "@mui/material";
import "./Navbar.css";
import { useState } from "react";

export default function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const pages = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Gallery", path: "/gallery" },
    { name: "Rooms", path: "/rooms" },
    { name: "Amenities", path: "/amenities" },
    { name: "Contact", path: "/contact" },
    { name: "Book", path: "/book" } // Added Book page
  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <a href="/">
        <img src={logo} alt="logo" className="logo"></img>
      </a>
      <button 
        className="navbar-toggler" 
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded={!isNavCollapsed ? true : false} 
        aria-label="Toggle navigation" 
        onClick={() => setIsNavCollapsed(!isNavCollapsed)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
        {pages.map((page) => (
          <a key={page.name} href={page.path} className="pages" style={{ textDecoration: 'none' }}>
            <Typography textAlign="center">{page.name}</Typography>
          </a>
        ))}
        <div id="page-container">
          <IconContext.Provider value={{ color: "#fa9a3a", className: "global-class-name" }}>
            <a href="https://www.facebook.com/inGyumri/?ref=page_internal" className="icon">
              <RiFacebookLine />
            </a>
            <a href="https://instagram.com/ingyumri.hotel?igshid=YmMyMTA2M2Y=" className="icon">
              <RiInstagramLine />
            </a>
            <a href="tel:+37493883334" className="icon">
              <RiPhoneLine />
            </a>
            <a href="mailto:ingyumri@gmail.com" className="icon">
              <RiMailLine />
            </a>
          </IconContext.Provider>
        </div>
      </div>
    </nav>
  );
}
