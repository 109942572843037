import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import '../styles/home.css';
import chair from "../assets/property/carousel_image_30.png";

function Home() {
    return (
        <div className="home-container">
            <Navbar />
            <div className="scrollable-container">
            <div className="home-section" style={{ backgroundImage: `url(${chair})` }}>
                <div className="overlay-home">
                    <h1>Welcome to In Gyumri</h1>
                    <h5>Your Next Unforgettable Getaway</h5>
                </div>
            </div>
            <div className="highlights-section">
                <div className="highlight">
                    <h2>Comfort</h2>
                    <p>Enjoy ultimate comfort with modern amenities in every room.</p>
                </div>
                <div className="highlight">
                    <h2>Relaxation and Recreation</h2>
                    <p>Indulge in our relaxing sauna, invigorating cold water dip, and fully-equipped gyms.</p>
                </div>
                <div className="highlight">
                    <h2>Complimentary Amenities</h2>
                    <p>Start your day with a complimentary breakfast and stay connected with free Wi-Fi.</p>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;