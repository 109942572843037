import Navbar from '../components/Navbar/Navbar';
import { Footer } from '../components/Footer/Footer';
import backgroundImage from "../assets/rooms/111111.png"; // Use an appropriate background image
import '../styles/amenities.css';

function Amenities() {
    return (
        <>
            <Navbar />
            <div className="amenities-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="overlay">
                    <section>
                        <h3>Our Amenities</h3>
                        <p>Discover the wide range of amenities we offer at In Gyumri:</p>
                    </section>
                    <section>
                        <h3>Relaxation and Recreation</h3>
                        <p>Guests can indulge in our relaxing sauna, invigorating cold water dip, and fully-equipped gyms. For business needs, we offer well-appointed conference areas. Outdoors, unwind in our gazebos, hammocks, and ample seating areas, complete with BBQ and tandoori pits for a delightful outdoor dining experience with beautiful views of Mount Aragats.</p>
                    </section>
                    <section>
                        <h3>Modern Comfort</h3>
                        <p>Each of our rooms is designed for ultimate comfort, featuring amenities such as a TV, mini-fridge, tea/coffee makers, and private bathroom. Select rooms contain a kitchenette with a dishwasher, microwave and a fridge.</p>
                    </section>
                    <section>
                        <h3>Complimentary Amenities</h3>
                        <p>Enjoy a complimentary breakfast in our cozy dining area and stay connected with free Wi-Fi throughout the property. We also provide free parking for your convenience. Take advantage of our on-site gym to maintain your fitness routine, and unwind in our relaxing sauna.</p>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Amenities;