import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material'; // Import Material-UI components
import '../styles/about.css';
import chair from '../assets/property/carousel_image_3.jpg';

function About() {
    return (
        <>
            <Navbar />
            <div className="about-section" style={{ height: 'calc(100vh - 64px)', overflowY: 'auto', backgroundImage: `url(${chair})`, backgroundSize: 'auto'}}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card className="card">
                                <CardContent>
                                    <Typography className="text" variant="h4" gutterBottom>Welcome to In Gyumri</Typography>
                                    <Typography className="text" variant="body1">
                                        Your serene getaway nestled in the heart of Gyumri, Armenia. Our hotel boasts two beautiful properties set within an expansive, lush garden filled with vibrant flowers, towering trees, and tranquil fountains. From comfortable accommodations to delightful dining options and convenient amenities, we provide everything you need for a pleasant and memorable stay.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className="card">
                                <CardContent>
                                    <Typography className="text" variant="h4" gutterBottom>Modern Comfort</Typography>
                                    <Typography className="text" variant="body1">
                                        Experience unparalleled comfort in our meticulously designed rooms and suites. Each room is tastefully furnished with amenities to ensure a relaxing stay. Enjoy amenities such as plush bedding, high-speed Wi-Fi, and TVs for your entertainment.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className="card">
                                <CardContent>
                                    <Typography className="text" variant="h4" gutterBottom>Dining</Typography>
                                    <Typography className="text" variant="body1">
                                        Enjoy daily buffet and continental breakfast options, along with access to a fully equipped kitchen and outdoor grilling area for guest use.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className="card">
                                <CardContent>
                                    <Typography className="text" variant="h4" gutterBottom>Recreation and Wellness</Typography>
                                    <Typography className="text" variant="body1">
                                        Unwind and rejuvenate with our recreational facilities and wellness services. Whether you prefer to stroll through the gardens, a game of table tennis, relaxing in the sauna, or maintaining your fitness routine at our gym, we have something for everyone. Our dedicated staff is here to ensure your wellness needs are met throughout your stay.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '64px' }}>
                            <Card className="card">
                                <CardContent>
                                    <Typography className="text" variant="h4" gutterBottom>Events and Meetings</Typography>
                                    <Typography className="text" variant="body1">
                                        Host your next event or meeting with us. Whether it's a corporate conference, wedding reception, or social celebration, we accommodate gatherings of all sizes. 
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Footer />
        </>
    );
}

export default About;
