import React, { useEffect, useState } from 'react';
import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import AWS from 'aws-sdk';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/rooms.css';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

type FolderImages = {
    [folder: string]: string[];
};

const S3ImageGallery: React.FC = () => {
    const [folderImages, setFolderImages] = useState<FolderImages>({});

    useEffect(() => {
        AWS.config.update({
            accessKeyId: 'AKIA6GBMDKC2D4L3DNLW',
            secretAccessKey: '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',
            region: 'us-east-1'
        });

        const s3 = new AWS.S3();
        const bucketName = 'in-gyumri-hotel';

        const listObjects = async (bucketName: string, prefix?: string): Promise<AWS.S3.ListObjectsV2Output> => {
            const params: AWS.S3.ListObjectsV2Request = {
                Bucket: bucketName,
                Prefix: prefix,
                Delimiter: '/',
            };
            return s3.listObjectsV2(params).promise();
        };

        const generatePresignedUrl = (bucketName: string, key: string): string => {
            const params = {
                Bucket: bucketName,
                Key: key,
                Expires: 60 * 60, // URL expiration time in seconds
            };

            return s3.getSignedUrl('getObject', params);
        };

        const fetchFolders = async (): Promise<string[]> => {
            try {
                const data = await listObjects(bucketName);
                return (data.CommonPrefixes || []).map(prefix => prefix.Prefix).filter(Boolean) as string[];
            } catch (err) {
                console.error('Error listing folders:', err);
                return [];
            }
        };

        const fetchImageUrls = async () => {
            const folders = await fetchFolders();
            const images: FolderImages = {};

            for (const folder of folders) {
                try {
                    const data = await listObjects(bucketName, folder);
                    const urls = (data.Contents || [])
                        .filter((item: any) => item.Key.startsWith(folder + 'msg'))
                        .map((item: any) => generatePresignedUrl(bucketName, item.Key));
                    images[folder] = urls;
                } catch (err) {
                    console.error(`Error listing objects in folder ${folder}:`, err);
                }
            }
            setFolderImages(images);
        };

        fetchImageUrls();
    }, []);

    return (
        <>
            <Navbar />
            <div className="scrollable-container">
                {Object.keys(folderImages).length > 0 ? (
                    Object.keys(folderImages).map(folder => (
                        <div key={folder}>
                            <Divider>
                                <h2>{folder.replace(/\//g, '')}</h2>
                            </Divider>
                            <Carousel className="carousel">
                                {folderImages[folder].map((url, index) => (
                                    <div key={index}>
                                        <img src={url} alt={index.toString()} className="carousel-image" />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    ))
                ) : (
                    <CircularProgress sx={{marginTop: '40vh'}}/>
                )}
            </div>
            <Footer />
        </>
    );
};

export default S3ImageGallery;
