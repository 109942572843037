import React from 'react';
import {
    MDBCarousel,
    MDBCarouselItem,
} from 'mdb-react-ui-kit';
import './carousel.scss';

// Import all images from the assets directory
const images = require.context('../../assets/rooms', false, /\.(png|jpe?g|svg)$/);

const RoomCarousel = () => {
    return (
        <div className="carousel-wrapper">
            <MDBCarousel showControls style={{ width: '80vw', maxHeight: '85vh', margin: 'auto' }}>
                {images.keys().map((imagePath, index) => {
                    const image = images(imagePath);
                    return (
                        <MDBCarouselItem
                            key={index}
                            itemId={index + 1}
                            className='w-50 d-block'
                            src={image}
                            style={{ maxHeight: '85vh', margin: 'auto' }}
                        />
                    );
                })}
            </MDBCarousel>
        </div>
    );
};

export default RoomCarousel;
