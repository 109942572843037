import React, { useState } from 'react';
import AmenitiesCarousel from "../components/ImageCarousel/AmenitiesCarousel";
import PropertyCarousel from "../components/ImageCarousel/PropertyCarousel";
import RoomsCarousel from "../components/ImageCarousel/RoomsCarousel";
import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import '../styles/gallery.css';

function Home() {
    const [imageType, setImageType] = useState<'showRoom' | 'showProperty' | 'showAmenities'>('showRoom');

    return (
        <div className="home-container">
            <Navbar />
            <div className="button-container">
                <button
                    className={imageType === 'showRoom' ? 'selected' : ''}
                    onClick={() => setImageType('showRoom')}
                >
                    Rooms
                </button>
                <button
                    className={imageType === 'showProperty' ? 'selected' : ''}
                    onClick={() => setImageType('showProperty')}
                >
                    Property
                </button>
                <button
                    className={imageType === 'showAmenities' ? 'selected' : ''}
                    onClick={() => setImageType('showAmenities')}
                >
                    Amenities
                </button>
            </div>
            <div className="carousel-container">
                {imageType === 'showAmenities' && <AmenitiesCarousel />}
                {imageType === 'showRoom' && <RoomsCarousel />}
                {imageType === 'showProperty' && <PropertyCarousel />}
            </div>
            <Footer />
        </div>
    );
}

export default Home;
