import { RiUsbLine, RiPhoneLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { FiMapPin } from "react-icons/fi";
import { CDBModalFooter, CDBBox } from "cdbreact";
import { FlagIcon } from 'react-flag-kit'; // Correct import
import "../../styles/App.css";
import "./Footer.css";

export const Footer = () => {
  return (
    <CDBModalFooter className="shadow footer-container">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="footer-content mx-auto py- flex-wrap"
      >
        <CDBBox className="footer-copyright">
          <small>&copy; In Gyumri, 2024. All rights reserved.</small>
        </CDBBox>
        <CDBBox display="flex" className="footer-info">
          <IconContext.Provider
            value={{ color: "#fa9a3a", className: "global-class-name" }}
          >
            <div className="footer-address">
              <FiMapPin />
              <a
                href="https://www.google.com/maps/place/In+Gyumri/@40.7618603,43.236468,10z/data=!4m14!1m2!2m1!1s32,+1+Southeast+bypass,+Gyumri+3112,+Armenia!3m10!1s0x4041fdaf7cb4e9df:0x79df6490e91c4c1f!5m3!1s2024-06-19!4m1!1i2!8m2!3d40.7618603!4d43.8462092!15sCiwzMiwgMSBTb3V0aGVhc3QgYnlwYXNzLCBHeXVtcmkgMzExMiwgQXJtZW5pYVorIikzMiAxIHNvdXRoZWFzdCBieXBhc3MgZ3l1bXJpIDMxMTIgYXJtZW5pYZIBBWhvdGVs4AEA!16s%2Fg%2F11j1q7blh8?entry=ttu"
              >
                32, 1 Southeast bypass, Gyumri 3112, Armenia
              </a>
            </div>
            <div className="footer-phone">
              <RiPhoneLine />
              <a href="tel:+37493883334">
                <FlagIcon code="AM" size={16} /> {/* Changed to FlagIcon */}
                +374 93-883-334
              </a>
            </div>
            <div className="footer-phone">
              <RiUsbLine />
              <a href="tel:+16267550240">
                <FlagIcon code="US" size={16} /> {/* Changed to FlagIcon */}
                +1 626-755-0240
              </a>
            </div>
          </IconContext.Provider>
        </CDBBox>
      </CDBBox>
    </CDBModalFooter>
  );
};
