import Navbar from '../components/Navbar/Navbar';
import { Footer } from '../components/Footer/Footer';
import backgroundImage from "../assets/property/carousel_image_22.jpeg";
import '../styles/book.css';

function Book() {
    const handleBookNow = () => {
        window.location.href = "https://www.booking.com/hotel/am/in-gyumri-giumri.html";
    };

    return (
        <div>
            <Navbar />
            <div className="book-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="overlay-book">
                    <h1>Book Your Stay</h1>
                    <p>Click the button below to book your stay at In Gyumri through Booking.com.</p>
                    <button onClick={handleBookNow} className="book-button">
                        Book Now
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Book;
